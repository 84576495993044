import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/Docs.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Get Involved`}</h1>
    <h4>{`Collaborating on creative projects`}</h4>
    <p>{`What profiles of folks should get involved, form to express what they're interested in`}</p>
    <h4>{`Support on Patreon`}</h4>
    <p>{`Info on patreon and what funding goes to`}</p>
    <h4>{`Learn to become a musician`}</h4>
    <p>{`Links to resources`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      